<template>
  <div class="places-agenda" v-if="canView" id="cal">
    <page-header
      title="Occupation des lieux"
      icon="fa fa-location-dot"
      :links="getLinks()"
    >
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <b-row>
        <b-col cols="10">
          <check-box-select
            :choices="listOfPlaces"
            inline=""
            :initial-value="selectedPlaces"
            @changed="onPlacesChanged"
            :style-callback="getChoiceStyle"
            small
          ></check-box-select>
        </b-col>
        <b-col cols="2" class="text-right">
          <date-selector weekly block small-buttons v-model="theDateAsString" id="seanceDay">
          </date-selector>
          <div style="background: #eee; margin: 1px 0">
            <b-form-checkbox class="small" v-model="showSunday">
              Dimanche
            </b-form-checkbox>
          </div>
          <div v-if="absencesCount > 0" style="background: #eee; margin-bottom: 1px">
            <b-form-checkbox class="small" v-model="showAbsence">
              <counter-label :counter="absencesCount" label="absence"></counter-label>
            </b-form-checkbox>
          </div>
          <div v-if="commentsCount > 0" style="background: #eee; margin-bottom: 1px">
            <b-form-checkbox class="small" v-model="showComments">
              <counter-label :counter="commentsCount" label="commentaire"></counter-label>
            </b-form-checkbox>
          </div>
          <div v-if="activitiesCount" style="background: #eee; margin-bottom: 1px">
            <b-form-checkbox v-model="onlyActivities" class="small" >
              <counter-label :counter="activitiesCount" label="activité"></counter-label>
            </b-form-checkbox>
          </div>
          <div style="background: #eee; margin-bottom: 1px">
            <a href @click.prevent="selectAvailablePlaces" class="small-text">
              Cocher les lieux la semaine
            </a>
          </div>
        </b-col>
      </b-row>
      <div ref="printMe">
        <div class="hide-here">
          <div
            style="display: inline-block"
            v-for="place in selectedPlaces"
            :key="place.id"
            :style="getChoiceStyle(place, true)"
          >{{ place.name }}</div>
        </div>
        <weekly-calendar
          :week-date="theDate"
          :events="events"
          :date-comments="dateComments"
          @clicked="onClickEvent"
          @new="onNewEvent"
          @comments-clicked="onCommentsClicked"
          :force-width="forceWidth"
          :can-add-comments="hasPerm('agenda.add_datecomment')"
          :can-edit-comments="hasPerm('agenda.change_datecomment')"
          :can-view-comments="hasPerm('agenda.view_datecomment')"
          :show-comments="showComments && (commentsCount > 0)"
          :allow-hide-sunday="!showSunday"
        ></weekly-calendar>
      </div>
    </div>
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok="onCloseModal"
      ok-variant="secondary"
      ok-only
      ok-title="Fermer"
    >
      <template v-slot:modal-title v-if="selectedEvent">
        <b><i class="fas fa-location-dot"></i> {{ selectedEvent.timeframe() }}</b>
      </template>
      <div v-if="selectedEvent">
        <div class="event-modal-line main-modal-line">
          <b-row>
            <b-col>
              {{ selectedEvent.hourframe() }} -
              <span :style="placeStyle(selectedEvent.place)">
                {{ selectedEvent.place.name }}
              </span>
              -
              {{ selectedEvent.getLabel() }}
              <span v-if="selectedEvent.absence" class="badge badge-warning">absence</span>
              <div v-if="selectedEvent.comments" class="event-comments">
                {{ selectedEvent.comments }}
              </div>
            </b-col>
            <b-col cols="4" class="text-right">
              <a
                class="btn btn-secondary btn-sm"
                href
                @click.prevent="onEditEvent()"
              >
                Modifier
              </a>
              &nbsp;
              <a
                class="btn btn-secondary btn-sm"
                href
                @click.prevent="onDeleteEvent()"
              >
                Supprimer
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-if="selectedEvent && selectedEvent.dateTypeId && participation" class="participations">
          <b-row>
            <b-col cols="10">
              <b>Participations {{ participation.name }}</b>
              <div class="participation-line" v-for="elt of participation.categories" :key="elt.id">
                <b-row>
                  <b-col cols="9">
                    <b-form-input type="text" :disabled="elt.id > 0" v-model="elt.name" placeholder="type de participants">
                    </b-form-input>
                  </b-col>
                  <b-col cols="3">
                    <b-form-input type="number" step="1" min="0" v-model="elt.participation">
                    </b-form-input>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="2" class="text-right">
              <br />
              <a class="btn btn-sm btn-secondary" href="" @click.prevent="changeParticipations()">
                Enregistrer
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-for="busy of busyEvents" :key="busy.id" class="event-modal-line">
          {{ busy.hourframe() }} -
          <span :style="placeStyle(busy.place)">{{ busy.place.name }}</span>
          - {{ busy.getLabel() }}
        </div>
        <br />
        <b>Disponibilités</b>
        <div v-if="freePlaces.length === 0">
          Aucune
        </div>
        <div v-else>
          <div
            v-for="freePlace of freePlaces"
            :key="freePlace.id"
             class="event-modal-line"
            :class="{'selected-free-place': (selectedFreePlace && freePlace.id === selectedFreePlace.id), }"
          >
            <b-row>
              <b-col>
                <a href @click.prevent="selectedFreePlace = freePlace">
                  <span :style="placeStyle(freePlace)">{{ freePlace.name }}</span>
                </a>
              </b-col>
              <b-col cols="3" class="text-right">
                <a
                  class="btn btn-secondary btn-sm"
                  v-if="selectedFreePlace === freePlace" href
                  @click.prevent="onNewEventForPlace(freePlace)"
                >
                  Ajouter une date
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-modal>
    <add-activity-date-modal
      :init-date-time="selectedDatetime"
      :places="thePlaces"
      :edit-event="editEvent"
      modal-id="bv-add-activity-date-modal"
      @done="onEventAdded"
      @updated="onEventUpdated"
    ></add-activity-date-modal>
    <confirm-modal
      name="delete-agenda-date"
      title="Supprimer la date"
      text="Confirmer la suppression de la date"
      :object="selectedEvent"
      @confirmed="onEventDeleted"
    >
    </confirm-modal>
    <edit-date-comments-modal
      modal-id="bv-edit-date-comments-modal"
      :edit-comment="dateComment"
      :day="dateCommentDay"
      @updated="onCommentUpdate($event)"
      @added="onCommentAdded($event)"
      @deleted="onCommentDeleted($event)"
    ></edit-date-comments-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapMutations, mapActions } from 'vuex'
import AddActivityDateModal from '@/components/Agenda/AddActivityDateModal'
import EditDateCommentsModal from '@/components/Agenda/EditCommentModal.vue'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import DateSelector from '@/components/Controls/DateSelector.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import WeeklyCalendar from '@/components/Controls/Calendar/WeeklyCalendar'
import PageHeader from '@/components/Layout/PageHeader'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeActivityDate, makeAgendaDateType, makeDateComment } from '@/types/agenda'
import { makePlace } from '@/types/base'
import { BackendApi, openDocument } from '@/utils/http'
import { distinct, existsIn } from '@/utils/arrays'
import { compareStrings } from '@/utils/sorting'
import { getColor } from '@/utils/colors'
import { dateToString } from '@/filters/texts'

export default {
  name: 'places-agenda',
  props: {
  },
  mixins: [BackendMixin],
  components: {
    DateSelector,
    EditDateCommentsModal,
    CounterLabel,
    ConfirmModal,
    AddActivityDateModal,
    WeeklyCalendar,
    CheckBoxSelect,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'placesAgenda',
      activityDates: [],
      dateComments: [],
      selectedPlaces: [],
      selectedEvent: null,
      modalId: 'bv-view-event-modal',
      busyEvents: [],
      freePlaces: [],
      allPlaces: [],
      thePlaces: [],
      selectedFreePlace: null,
      theDateAsString: '',
      validDateAsString: '',
      forceWidth: 0,
      selectedDatetime: null,
      editEvent: null,
      showAbsence: false,
      showComments: false,
      onlyActivities: false,
      dateComment: null,
      dateCommentDay: '',
      showAllPlaces: true,
      showSunday: false,
      participation: null,
    }
  },
  async created() {
    if (this.$route.query && this.$route.query.date) {
      this.theDateAsString = this.$route.query.date
    } else {
      this.theDateAsString = moment().format('YYYY-MM-DD')
    }
    if (this.$route.query && this.$route.places) {
      this.placeIds = this.$route.query.places.split('-').map(elt => +elt)
    }
    await this.loadData()
  },
  watch: {
    theDate: async function(newValue) {
      let isValid = false
      if (newValue) {
        isValid = moment(newValue, 'YYYY-MM-DD').isValid()
      }
      if (isValid) {
        await this.loadActivityDates()
        await this.loadDateComments()
      } else {
        this.theDateAsString = dateToString(moment().toDate(), 'YYYY-MM-DD')
      }
    },
    theDateAsString: function() {
      if (this.theDateAsString) {
        this.validDateAsString = this.theDateAsString
      }
      this.updateRoute()
    },
    selectedPlaces: function() {
      this.updateRoute()
    },
  },
  computed: {
    listOfPlaces() {
      return this.showAllPlaces ? this.allPlaces : this.places
    },
    theDate() {
      return moment(this.validDateAsString, 'YYYY-MM-DD').toDate()
    },
    canView() {
      return this.hasPerm('agenda.view_activitydate')
    },
    canChange() {
      return this.hasPerm('agenda.add_activitydate')
    },
    week() {
      return {
        startDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        hourStart: 6,
        eventView: ['time'],
        taskView: false,
        showNowIndicator: true,
        isReadOnly: false,
      }
    },
    places() {
      return distinct(this.activityDates.map(elt => elt.place)).sort(
        (place1, place2) => compareStrings(place1.name, place2.name)
      )
    },
    placeIds() {
      if (this.selectedPlaces.length !== this.places.length) {
        return this.selectedPlaces.map(elt => elt.id)
      } else {
        return []
      }
    },
    calendars() {
      return this.places.map(
        (place) => {
          const color = this.getColor(place)
          return {
            id: place.id,
            name: place.name,
            color: color.text,
            backgroundColor: color.background,
          }
        }
      )
    },
    absencesCount() {
      return this.placesDates.filter(elt => elt.absence).length
    },
    commentsCount() {
      return this.placesDates.filter(elt => elt.comments).length
    },
    placesDates() {
      const selectedPlaces = this.selectedPlaces.map(elt => elt.id)
      return this.activityDates.filter(
        event => existsIn([event.place.id], selectedPlaces)
      )
    },
    filteredPlaces() {
      return this.placesDates.filter(elt => this.showAbsence || !elt.absence)
    },
    activityPlaces() {
      return this.filteredPlaces.filter(
        elt => elt.activity && elt.activity.id
      )
    },
    activitiesCount() {
      return this.activityPlaces.length
    },
    shownEvents() {
      if (this.onlyActivities) {
        return this.activityPlaces
      } else {
        return this.filteredPlaces
      }
    },
    events() {
      return this.shownEvents.map(
        elt => {
          const color = this.getColor(elt.place)
          return {
            id: elt.id,
            title: elt.getLabel(),
            start: elt.startDateTime,
            end: elt.endDateTime,
            color: color.text,
            backgroundColor: color.background,
            raw: elt,
            siblings: this.getSiblings(elt),
            absence: elt.absence,
            opacity: elt.absence ? 0.3 : 1,
            comments: elt.comments,
          }
        }
      )
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    updateRoute() {
      const query = {
        date: this.theDateAsString,
      }
      if (this.placeIds.length) {
        query.places = this.placeIds.map(elt => '' + elt).join('-')
      }
      router.push({ path: this.$route.path, query: query, })
    },
    async loadData() {
      if (this.canView) {
        this.startLoading(this.loadingName)
        await this.loadAllPlaces()
        this.endLoading(this.loadingName)
      }
    },
    async loadActivityDates() {
      try {
        const today = moment(this.validDateAsString).format('YYYY-MM-DD')
        let url = '/agenda/api/week-dates/' + today + '/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activityDates = resp.data.map(makeActivityDate)
        if (this.placeIds.length) {
          this.selectedPlaces = this.listOfPlaces.filter(
            elt => existsIn([elt.id], this.placeIds)
          )
        } else {
          this.selectedPlaces = this.allPlaces
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    selectAvailablePlaces() {
      this.selectedPlaces = this.places
    },
    async loadDateComments() {
      if (this.hasPerm('agenda.view_datecomment')) {
        try {
          const today = moment(this.validDateAsString).format('YYYY-MM-DD')
          let url = '/agenda/api/date-comments/?weekly=1&base_date=' + today
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.dateComments = resp.data.map(makeDateComment)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        this.dateComments = []
      }
    },
    async loadAllPlaces() {
      try {
        let url = '/api/home/places/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.allPlaces = resp.data.map(makePlace)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    getChoiceStyle(place, pdf = false) {
      const color = this.getColor(place)
      return {
        color: color.text,
        backgroundColor: color.background,
        padding: '0px 2px',
        fontSize: pdf ? '11px' : '16px',
        margin: '1px',
      }
    },
    onCommentsClicked(event) {
      this.dateCommentDay = dateToString(event.day, 'YYYY-MM-DD')
      const matchingComments = this.dateComments.filter(
        elt => dateToString(elt.date, 'YYYY-MM-DD') === this.dateCommentDay
      )
      if (matchingComments.length > 0) {
        this.dateComment = matchingComments[0]
      } else {
        this.dateComment = null
      }
      this.$bvModal.show('bv-edit-date-comments-modal')
    },
    onClickEvent(event) {
      this.selectedEvent = event.event.raw
      this.selectedFreePlace = null
      this.getAvailabilities(this.selectedEvent)
      this.loadParticipations()
      this.$bvModal.show(this.modalId)
    },
    onNewEvent(event) {
      this.editEvent = null
      this.thePlaces = this.allPlaces
      this.selectedDatetime = moment(event.date)
      this.$bvModal.show('bv-add-activity-date-modal')
    },
    onNewEventForPlace(freePlace) {
      this.$bvModal.hide(this.modalId)
      this.editEvent = null
      this.selectedDatetime = moment(this.selectedEvent.startDateTime)
      this.thePlaces = [freePlace]
      this.$bvModal.show('bv-add-activity-date-modal')
    },
    onDeleteEvent() {
      this.$bvModal.hide(this.modalId)
      this.$bvModal.show('bv-confirm-modal:delete-agenda-date')
    },
    onEditEvent() {
      this.$bvModal.hide(this.modalId)
      this.editEvent = this.selectedEvent
      this.thePlaces = this.allPlaces
      this.$bvModal.show('bv-add-activity-date-modal')
    },
    selectNewPlace(newDate) {
      const selectedIndex = this.selectedPlaces.map(elt => elt.id).indexOf(newDate.place.id)
      if (selectedIndex < 0) {
        const index = this.thePlaces.map(elt => elt.id).indexOf(newDate.place.id)
        if (index >= 0) {
          this.selectedPlaces.push(this.thePlaces[index])
        }
      }
    },
    onEventAdded(event) {
      const newDate = makeActivityDate(event)
      this.activityDates.push(newDate)
      this.selectNewPlace(newDate)
    },
    onEventUpdated(event) {
      const updated = makeActivityDate(event)
      const index = this.activityDates.map(elt => elt.id).indexOf(updated.id)
      if (index >= 0) {
        this.activityDates[index] = updated
        this.activityDates = [].concat(this.activityDates)
      }
      this.selectNewPlace(updated)
    },
    async onEventDeleted(event) {
      let url = '/agenda/api/activity-dates/' + event.object.id + '/'
      let backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('La date a été supprimée')
        const index = this.activityDates.map(elt => elt.id).indexOf(event.object.id)
        if (index >= 0) {
          this.activityDates.splice(index, 1)
          this.activityDates = [].concat(this.activityDates)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onCloseModal() {
      this.$bvModal.hide(this.modalId)
    },
    getAvailabilities(event) {
      let busyEvents = []
      const freePlaces = []
      const absencePlaces = []
      for (const place of this.allPlaces) {
        const placeDates = this.activityDates.filter(elt => elt.place.id === place.id)
        let notFound = true
        for (const placeDate of placeDates) {
          if (placeDate.absence) {
            absencePlaces.push(placeDate)
          } else if (placeDate.id === event.id) {
            notFound = false
          } else if (placeDate.match(event)) {
            busyEvents.push(placeDate)
            notFound = false
          }
        }
        if (notFound) {
          freePlaces.push(place)
        }
      }
      this.busyEvents = busyEvents
      this.freePlaces = freePlaces
    },
    getSiblings(event) {
      let busyEvents = []
      let index = 0
      for (const place of this.selectedPlaces) {
        const placeDates = this.activityDates.filter(elt => elt.place.id === place.id)
        for (const placeDate of placeDates) {
          if (placeDate.id === event.id) {
            // événement qui nous intéresse, quelle est sa position dans la liste
            index = busyEvents.length
            busyEvents.push(placeDate)
          } else if (placeDate.match(event)) {
            // autre événement simultané à celui qui nous intéresse, ajoute dans la liste pour les compter
            busyEvents.push(placeDate)
          }
        }
      }
      // retourne le nombre d'événements simultanés et la position de l'événement en cours dans cette liste
      return {
        index: index,
        count: busyEvents.length,
      }
    },
    getColor(place) {
      if (place.backgroundColor) {
        return { background: place.backgroundColor, front: place.textColor, }
      } else {
        const placeIndex = this.listOfPlaces.map(elt => elt.id).indexOf(place.id)
        return getColor(placeIndex, true)
      }
    },
    placeStyle(place) {
      const color = this.getColor(place)
      return {
        display: 'inline-block',
        color: color.text,
        backgroundColor: color.background,
        padding: '1px 5px',
      }
    },
    onCommentUpdate(event) {
      const index = this.dateComments.map(elt => elt.id).indexOf(event.id)
      if (index >= 0) {
        this.dateComments[index] = event
        this.dateComments = [].concat(this.dateComments)
      }
    },
    onCommentDeleted(event) {
      const index = this.dateComments.map(elt => elt.id).indexOf(event.id)
      if (index >= 0) {
        this.dateComments.splice(index, 1)
        this.dateComments = [].concat(this.dateComments)
      }
    },
    onCommentAdded(event) {
      this.dateComments.push(event)
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1&colors=1'
      const docSlug = 'occupations-salles-' + this.validDateAsString
      this.forceWidth = 1020
      const that = this
      setTimeout(
        async function() {
          const docContent = that.$refs.printMe.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
            that.forceWidth = 0
          } catch (err) {
            await that.addError(that.getErrorText(err))
          }
        },
        100
      )
    },
    onPlacesChanged(event) {
      this.selectedPlaces = event.choices
    },
    async loadParticipations() {
      if (this.selectedEvent && this.selectedEvent.dateTypeId) {
        this.participation = null
        let url = '/agenda/api/agenda-date-participations/' + this.selectedEvent.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.participation = makeAgendaDateType(resp.data)
        } catch (err) {
          this.error = this.getErrorText(err)
        }
      } else {
        this.participation = null
      }
    },
    async changeParticipations() {
      if (this.selectedEvent && this.selectedEvent.dateTypeId) {
        let url = '/agenda/api/agenda-date-participations/' + this.selectedEvent.id + '/'
        const backendApi = new BackendApi('post', url)
        try {
          const categories = []
          for (const elt of this.participation.categories) {
            categories.push(
              {
                category: elt.id,
                participation: elt.participation,
              }
            )
          }
          const resp = await backendApi.callApi({ 'categories': categories, })
          this.participation = makeAgendaDateType(resp.data)
          await this.addSuccess('Les participations ont été enregistrées')
        } catch (err) {
          let text1 = 'Une erreur est survenue lors de la sauvegarde des participations'
          await this.addError(text1 + ': ' + this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
.selected-free-place {
  background: #eee;
}
.event-modal-line {
  padding: 3px 0;
  border-bottom: solid 1px #f0f0f0;
  margin-bottom: 1px;
}
.main-modal-line {
  background: #eee;
}
.event-comments {
  font-style: italic;
  font-size: 14px;
  color: #444;
}
.participations {
  border: solid 1px #eee;
  margin: 5px 0;
  padding: 5px;
  border-radius: 4px;
  background: #f0f0f0;
}
.participation-line {
  font-size: 12px;
  input {
    font-size: 12px;
  }
  padding: 2px 0;
}
</style>
